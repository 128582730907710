import React from 'react'
import classNames from 'classnames'
import { Children, useEffect, useRef, useState } from 'react'
import ReactSelect from 'react-select'
import { AccessorContext, isNodeAnAccessor } from './selectFieldUtils/accessors'
import { LoadingIndicator } from './selectFieldUtils/LoadingIndicator'
import { MenuList } from './selectFieldUtils/MenuList'
import { MultiValueContainer } from './selectFieldUtils/MultiValueContainer'
import { NoOptionsMessage } from './selectFieldUtils/NoOptionsMessage'
import { Option } from './selectFieldUtils/Option'
import { FormFeedback, FormText, Input, Label } from 'reactstrap'

export function AsyncMultiSelectField({
  layout = 'vertical',
  label,
  helperText,
  placeholder,
  labelColSize,
  inputColSize,
  value,
  onInputChange,
  onChange,
  readonly,
  disabled,
  isValid,
  successMessage,
  errorMessage,
  size,
  loadInitialValues,
  loadOptions,
  clearable = false,
  hideSelectedOptions = false,
  children,
  required = false,
  noOptionMessage,
  className,
  style,
}) {
  const loadingOperationRef = useRef(0)
  const [loading, setLoading] = useState(null)

  const [currentOptions, setCurrentOptions] = useState([])
  const [optCache, setOptCache] = useState(new Map())
  const isSelectedOptionLoading = useRef(false)
  const didLoadOptions = useRef(false)

  const selectedOptions = (value ?? [])
    .map((val) => optCache.get(val))
    .filter((opt) => opt !== undefined)

  const accessors = Children.toArray(children).filter((child) =>
    isNodeAnAccessor(child)
  )

  const requiredMarker = required ? (
    <span className="text-danger">*</span>
  ) : undefined
  const labelCmp = label ? (
    <Label className="mb-0">
      {label}
      {requiredMarker}
    </Label>
  ) : undefined
  const helperTextCmp = helperText ? (
    <FormText className="text-muted mt-0">{helperText}</FormText>
  ) : undefined

  useEffect(() => {
    if (
      value &&
      selectedOptions.length < value.length &&
      !isSelectedOptionLoading.current
    ) {
      isSelectedOptionLoading.current = true
      loadInitialValues(value)
        .then((opt) => {
          setOptCache((prev) => {
            const newCache = new Map(prev)
            for (const o of opt) {
              newCache.set(o.value, o)
            }
            return newCache
          })
        })
        .finally(() => {
          isSelectedOptionLoading.current = false
        })
    }
  }, [loadInitialValues, selectedOptions, value])

  useEffect(() => {
    if (!didLoadOptions.current) {
      didLoadOptions.current = true
      loadOptions('')
        .then((opts) => {
          setCurrentOptions(opts)
        })
        .catch(() => {
          didLoadOptions.current = false
        })
    }
  }, [loadOptions])

  let controlCmp = null
  if (!readonly) {
    controlCmp = (
      <ReactSelect
        classNames={{
          container: () => classNames('flex-1'),
          control: () =>
            classNames('form-control', {
              'form-control-sm': size === 'sm',
              'form-control-lg': size === 'lg',
              'bs-border-valid': isValid === true,
              'bs-border-invalid': isValid === false,
              'bs-disabled-bg': disabled,
            }),
        }}
        // styles={{
        //   placeholder: (baseStyles) => ({
        //     ...baseStyles,
        //     color: 'var(--bs-secondary-color)',
        //   }),
        //   option: (baseStyles) => ({
        //     ...baseStyles,
        //     cursor: 'pointer',
        //   }),
        //   control: (baseStyles) => ({
        //     ...baseStyles,
        //     minHeight: 'unset',
        //   }),
        // }}
        // components={{
        //   MenuList,
        //   Option,
        //   MultiValueContainer,
        //   NoOptionsMessage,
        //   LoadingIndicator,
        // }}
        // unstyled
        isClearable={clearable}
        options={currentOptions}
        placeholder={placeholder}
        onChange={(values) => {
          setOptCache((prev) => {
            const newCache = new Map(prev)
            for (const v of values) {
              newCache.set(v.value, v)
            }
            return newCache
          })
          if (onChange) {
            onChange(values.map((val) => val.value))
          }
        }}
        value={selectedOptions}
        isDisabled={disabled}
        hideSelectedOptions={hideSelectedOptions}
        isMulti
        onInputChange={(inputValue) => {
          const callId = ++loadingOperationRef.current
          setLoading(callId)
          loadOptions(inputValue)
            .then((opts) => {
              setCurrentOptions(opts)
            })
            .finally(() => {
              setLoading((l) => (l === callId ? null : l))
            })
          if (onInputChange) {
            onInputChange(inputValue)
          }
        }}
        noOptionsMessage={
          typeof noOptionMessage === 'string'
            ? () => noOptionMessage
            : noOptionMessage
        }
        filterOption={() => true}
        isLoading={loading !== null}
      />
    )
  } else {
    controlCmp = (
      <Input
        size={size === 'md' ? undefined : size}
        type="text"
        value={currentOptions.find((opt) => opt.value === value)?.label ?? ''}
        placeholder={placeholder}
        readOnly={true}
        className={'p-0'}
      />
    )
  }

  let semanticTextCmp = undefined
  if (errorMessage) {
    semanticTextCmp = <FormFeedback invalid>{errorMessage}</FormFeedback>
  } else if (successMessage) {
    semanticTextCmp = <FormFeedback valid>{successMessage}</FormFeedback>
  }

  if (layout === 'horizontal') {
    return (
      <div className={classNames(`form-group ${className}`)} style={style}>
        <div className="d-flex flex-column">
          {labelCmp}
          {helperTextCmp}
        </div>
        <div className="d-flex flex-column align-items-stretch">
          <div
            
            className={classNames('w-100', {
              'is-valid': isValid === true,
              'is-invalid': isValid === false,
            })}
          >
            {controlCmp}
            {accessors.length > 0 && !readonly && (
              <AccessorContext.Provider value={{ disabled: disabled ?? false }}>
                <div className="d-flex" gap={2}>
                  {accessors}
                </div>
              </AccessorContext.Provider>
            )}
          </div>
          {semanticTextCmp}
        </div>
      </div>
    )
  } else {
    return (
      <div className={classNames(`form-group ${className}`)} style={style}>
        <div className="d-flex flex-column">
          {labelCmp}
          {helperTextCmp}
          <div
            className={classNames('w-100', {
              'is-valid': isValid === true,
              'is-invalid': isValid === false,
            })}
          >
            {controlCmp}
            {accessors.length > 0 && !readonly && (
              <AccessorContext.Provider value={{ disabled: disabled ?? false }}>
                <div className="d-flex" gap={2}>
                  {accessors}
                </div>
              </AccessorContext.Provider>
            )}
          </div>
          {semanticTextCmp}
        </div>
      </div>
    )
  }
}
