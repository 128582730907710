import React from 'react'
import moment from 'moment'

const COLOR_ACTIVITIES = 'rgba(255, 0, 0, 0.3)'

const TableLavorazioniOrdine = ({
  lavorazioniOrdine,
  totals,
  showOldInfo = true,
}) => {
  console.log('lavorazioniOrdine', lavorazioniOrdine)
  return (
    <table className="table table-bordered table-hover table-striped mt-2">
      <thead>
        <tr>
          <td colSpan={5} align="right"></td>

          <td>
            <div className="badge badge-primary">{totals.numero_pezzi}</div>
          </td>

          <td>
            <div className="badge badge-primary">
              {parseInt(
                moment.duration(totals.tempo_teorico_tot).as('minutes')
              ) + ' min'}
              {' ('}
              {/* show numero gg numero hh numero mm  if the days are more than 30 we conitnue showing them as days */}
              {Math.floor(moment.duration(totals.tempo_teorico_tot, 'minutes').asDays())}g{' '}
              {moment.duration(totals.tempo_teorico_tot, 'minutes').hours()}h{' '}
              {moment.duration(totals.tempo_teorico_tot, 'minutes').minutes()}m
              {')'}
            </div>
          </td>
          <td>
            <div className="badge badge-primary">
              {parseInt(moment.duration(totals.tempo_tot).as('minutes')) +
                ' min'}
              {' ('}
              {/* show numero gg numero hh numero mm */}
              {Math.floor(moment.duration(totals.tempo_tot, 'minutes').asDays())}g{' '}
              {moment.duration(totals.tempo_tot, 'minutes').hours()}h{' '}
              {moment.duration(totals.tempo_tot, 'minutes').minutes()}m
              {')'}
            </div>
            <div className="badge badge-secondary"></div>
          </td>
        </tr>
        <tr>
          <th>Ordine</th>
          <th>Lavorazione</th>
          <th>Operatore</th>
          <th>Data inizio</th>
          <th>Data fine</th>
          <th>Unità</th>
          <th>Tempo teorico</th>
          <th>Tempo</th>
          <th>Note</th>
          {!showOldInfo && <th>Performance</th>}
        </tr>
      </thead>
      <tbody>
        {lavorazioniOrdine.map((lavorazione, i) => (
          <tr
            style={{
              background:
                lavorazione.tempo_teorico < lavorazione.tempo
                  ? COLOR_ACTIVITIES
                  : '',
            }}
            key={i}
          >
            <td className="text-nowrap">
              {lavorazione.ordine_numero_commessa} -{' '}
              {lavorazione.ordine_nome_cliente}
            </td>
            <td>{lavorazione.lavorazione_nome}</td>
            <td className="text-nowrap">
              {lavorazione.operatore_fist_name}{' '}
              {lavorazione.operatore_last_name}
            </td>
            <td className="text-nowrap">
              {moment(lavorazione.data_inizio).format('DD-MM-YYYY')}
            </td>
            <td className="text-nowrap">
              {moment(lavorazione.data_fine).format('DD-MM-YYYY')}
            </td>
            <td>{lavorazione.unita}</td>

            <td className="text-nowrap">
              {lavorazione.tempo_teorico
                ? parseInt(
                    moment.duration(lavorazione.tempo_teorico).as('minutes')
                  ) + ' min'
                : ''}
            </td>
            <td className="text-nowrap">
              {lavorazione.tempo
                ? parseInt(moment.duration(lavorazione.tempo).as('minutes')) +
                  ' min'
                : ''}
            </td>
            <td>{lavorazione.note ? lavorazione.note.map((n) => n ? n+', ' : '') : ''}</td>
            {!showOldInfo && (
              <td>
                {(
                  (parseInt(
                    moment.duration(lavorazione.tempo_teorico).as('minutes')
                  ) /
                    parseInt(
                      moment.duration(lavorazione.tempo).as('minutes')
                    )) *
                  100
                ).toFixed(0)}
                %
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default TableLavorazioniOrdine
