import React, { useMemo, useState } from 'react'
import { useRj, useRunRj } from 'react-rocketjump'
import {
  useQsDataTable as useDataTable,
  useQsDataTable,
} from 'react-use-datatable/react'
import Select from 'react-select'
import { LavorazioniState } from '../localstate/lavorazioni'
import Paginator from '../../../components/Paginator'
import Spinner from 'reactstrap/lib/Spinner'
import { OperatoriState } from '../localstate/operatori'
import DateRangeQsFilter from '../../../components/Planner/DateRangeQsFilter'
import OrdersSelectAutoComplete from '../../../components/OrdersSelectAutoComplete'
import classNames from 'classnames'
import moment from 'moment'
import {
  AvanzamentiOrdiniState,
  AvanzamentoOrdinePerCommessaState,
  AvanzamentoPerGiornoState,
  DownloadAvanzamentiState,
} from '../localstate'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { sumBy } from 'lodash'
// import { AttivitaAdminState } from '../localstate/attivita'

const AVANZAMENTI_TIPO_EVENTO = {
  attivita_operatore: 'Attività operatore',
  arrivo_materiale: 'Arrivo materiale',
  differenza_avanzamento: 'Differenza avanzamento',
}

const BIG_PAGE_PARAMS = { page_size: 1000 }

export default function AvanzamentoOrdini() {
  const [{ lavorazioni }] = useRunRj(LavorazioniState, [BIG_PAGE_PARAMS])
  const [{ operatori }] = useRunRj(OperatoriState, [BIG_PAGE_PARAMS])

  const [
    // Data State
    { avanzamenti, pagination, loading, totals },
    // Table state
    { page, ...filters },
    {
      // DT Actions
      onFiltersChange,
      onFilterChange,
      goToPage,
    },
  ] = useDataTable(AvanzamentiOrdiniState, () => ({
    operatore: '',
    lavorazione: '',
    ordine: '',
    from_date: moment().startOf('week').format('YYYY-MM-DD'),
    to_date: moment().endOf('week').format('YYYY-MM-DD'),
    lavorazioni: [],
    operatori: [],
    tipo_evento: '',
    materiale: '',
    tipo_agente: '',
  }))

  const [{ data }, { run: runDownloadAvanzamenti }] = useRj(
    DownloadAvanzamentiState
  )

  const filtersToUse = useMemo(() => {
    return {
      ...filters,
      page_size: 1000,
    }
  }, [filters])

  const [{ list: avanzamentiCommesse }] = useQsDataTable(
    AvanzamentoOrdinePerCommessaState,
    () => ({
      ...filtersToUse,
    })
  )

  const [{ list: avanzamentoPerGiorno }] = useQsDataTable(
    AvanzamentoPerGiornoState,
    () => ({
      ...filtersToUse,
    })
  )

  const lavorazioniForSelect = useMemo(() => {
    if (!lavorazioni) {
      return []
    }
    return lavorazioni.map((lavorazione) => ({
      value: lavorazione.id,
      label: lavorazione.nome,
    }))
  }, [lavorazioni])

  const operatoriForSelect = useMemo(() => {
    if (!operatori) {
      return []
    }
    return operatori.map((operatore) => ({
      value: operatore.id,
      label: operatore.user.first_name + ' ' + operatore.user.last_name,
    }))
  }, [operatori])

  const selectedOperatori = useMemo(() => {
    if (!filters.operatori || !operatori) {
      return []
    }
    if (typeof filters.operatori === 'string') {
      return filters.operatori.split(',').map((id) => ({
        value: id,
        label:
          operatori.find((operatore) => operatore.id === Number(id)).user
            .first_name +
          ' ' +
          operatori.find((operatore) => operatore.id === Number(id)).user
            .last_name,
      }))
    } else {
      return filters.operatori.map((id) => ({
        value: id,
        label:
          operatori.find((operatore) => operatore.id === Number(id)).user
            .first_name +
          ' ' +
          operatori.find((operatore) => operatore.id === Number(id)).user
            .last_name,
      }))
    }
  }, [filters.operatori, operatori])

  const selectedLavorazioni = useMemo(() => {
    if (!filters.lavorazioni || !lavorazioni) {
      return []
    }
    if (typeof filters.lavorazioni === 'string') {
      return filters.lavorazioni.split(',').map((id) => ({
        value: id,
        label: lavorazioni.find((lavorazione) => lavorazione.id === Number(id))
          .nome,
      }))
    } else {
      return filters.lavorazioni.map((id) => ({
        value: id,
        label: lavorazioni.find((lavorazione) => lavorazione.id === Number(id))
          .nome,
      }))
    }
  }, [filters.lavorazioni, lavorazioni])

  const [typeView, setTypeView] = useState('avanzamento-ordini')

  const intestazioneThMateriali = useMemo(() => {
    if (!avanzamentiCommesse) {
      return []
    }
    const materiali = []
    avanzamentiCommesse.forEach((av) => {
      av.valori_materiali.forEach((valore) => {
        if (!valore.materiale) return
        if (!materiali.includes(valore.materiale)) {
          materiali.push(valore.materiale)
        }
      })
    })
    return materiali
  }, [avanzamentiCommesse])

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="filters mt-2">
            <div className="row">
              <div className="col-md-2">
                <Select
                  name="operatori"
                  isMulti
                  placeholder={'Filtra per operatori'}
                  options={operatoriForSelect.filter(
                    (operatore) =>
                      !selectedOperatori.find(
                        (item) => Number(item.value) === Number(operatore.value)
                      )
                  )}
                  classNamePrefix="multi-select"
                  hideSelectedOptions={false}
                  isOptionSelected={(option) =>
                    selectedOperatori.find(
                      (item) => item.value === option.value
                    )
                  }
                  value={selectedOperatori}
                  className="basic-multi-select select-multi-options text-nowrap w-100"
                  onChange={(newValues) => {
                    if (newValues) {
                      const items = newValues.map((item) => item.value)
                      onFilterChange(items, 'operatori')
                    } else {
                      onFilterChange([], 'operatori')
                    }
                  }}
                />
              </div>
              <div className="col-md-2">
                <Select
                  name="lavorazioni"
                  isMulti
                  placeholder={'Filtra per lavorazioni'}
                  options={lavorazioniForSelect.filter(
                    (lavorazione) =>
                      !selectedLavorazioni.find(
                        (item) =>
                          Number(item.value) === Number(lavorazione.value)
                      )
                  )}
                  classNamePrefix="multi-select"
                  hideSelectedOptions={false}
                  isOptionSelected={(option) =>
                    selectedLavorazioni.find(
                      (item) => item.value === option.value
                    )
                  }
                  value={selectedLavorazioni}
                  className="basic-multi-select select-multi-options text-nowrap w-100"
                  onChange={(newValues) => {
                    if (newValues) {
                      const items = newValues.map((item) => item.value)
                      onFilterChange(items, 'lavorazioni')
                    } else {
                      onFilterChange([], 'lavorazioni')
                    }
                  }}
                />
              </div>
              <div className="col-md-2">
                <OrdersSelectAutoComplete
                  value={filters.ordine}
                  hasValue
                  debounceTimeout={150}
                  loadOptionsOnMenuOpen
                  onChange={(ordine) => {
                    if (ordine) {
                      onFiltersChange({ ordine: ordine.id })
                    } else {
                      onFiltersChange({ ordine: '' })
                    }
                  }}
                  noOptionsMessage={() => 'Nessun ordine trovato'}
                  loadingMessage={() => 'Caricamento ordini...'}
                  placeholder={'Filtra per ordine'}
                  isClearable={true}
                  name={'ordine'}
                  additional={{
                    page: 1,
                  }}
                />
              </div>
              <div className="col-md-2">
                <select
                  className="form-control"
                  value={filters.tipo_evento}
                  onChange={(e) => {
                    onFilterChange(e.target.value, 'tipo_evento')
                  }}
                >
                  <option value="">Filtra per tipo evento</option>
                  {Object.keys(AVANZAMENTI_TIPO_EVENTO).map((key) => (
                    <option key={key} value={key}>
                      {AVANZAMENTI_TIPO_EVENTO[key]}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <DateRangeQsFilter
                  filters={filters}
                  onFiltersChange={onFiltersChange}
                  startField="from_date"
                  endField="to_date"
                  withPortal={true}
                  customArrowIcon={<div />}
                  small={false}
                  noBorder
                />
              </div>
              <div className="col-md-2">
                <select
                  className="form-control"
                  value={filters.materiale}
                  onChange={(e) => {
                    onFilterChange(e.target.value, 'materiale')
                  }}
                >
                  <option value="">Filtra per materiale</option>
                  <option value="vetro">Vetro</option>
                  <option value="legno">Legno</option>
                  <option value="verniciatura">Verniciatura</option>
                  <option value="telai">Telai</option>
                </select>
              </div>
              <div className="col-md-2">
                <select
                  className="form-control"
                  value={filters.tipo_agente}
                  onChange={(e) =>
                    onFiltersChange({ tipo_agente: e.target.value })
                  }
                >
                  <option value={''}>Filtra per tipo cliente</option>
                  <option value={'B2C'}>B2C</option>
                  <option value={'B2B'}>B2B</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row mt-3 mb-2">
            <div className="col-md-8 mt-3">
              <div>
                <div
                  className={classNames('btn pointer', {
                    'btn-primary': typeView === 'avanzamento-ordini',
                    'btn-outline-primary': typeView !== 'avanzamento-ordini',
                  })}
                  onClick={() => {
                    setTypeView('avanzamento-ordini')
                  }}
                >
                  Avanzamento
                </div>
                <div
                  className={classNames('btn pointer', {
                    'btn-primary': typeView === 'avanzamento-per-commessa',
                    'btn-outline-primary':
                      typeView !== 'avanzamento-per-commessa',
                  })}
                  onClick={() => {
                    setTypeView('avanzamento-per-commessa')
                  }}
                >
                  Per commessa
                </div>
                <div
                  className={classNames('btn pointer', {
                    'btn-primary': typeView === 'per-giorno',
                    'btn-outline-primary': typeView !== 'per-giorno',
                  })}
                  onClick={() => {
                    setTypeView('per-giorno')
                  }}
                >
                  Per giorno
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3 text-right">
              <Button
                color="success"
                onClick={() => {
                  runDownloadAvanzamenti
                    .onSuccess((data) => {
                      // trigger download blob file

                      const url = window.URL.createObjectURL(new Blob([data]))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', 'avanzamenti.xlsx')
                      document.body.appendChild(link)
                      link.click()
                      document.body.removeChild(link)
                    })
                    .run(filtersToUse)
                }}
              >
                <i className="fas fa-file-excel mr-2"></i>
                Download Excel
              </Button>
            </div>
          </div>
          {loading && (
            <div className="text-center mt-4">
              <Spinner color="primary" />
            </div>
          )}
          {avanzamenti && !loading && (
            <div className="row">
              <div className="col-md-12">
                {typeView === 'avanzamento-ordini' && (
                  <>
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th>
                            <div className="badge badge-primary">
                              {moment
                                .duration(
                                  totals.tempo_teorico_totale,
                                  'seconds'
                                )
                                .days()}
                              g{' '}
                              {moment
                                .duration(
                                  totals.tempo_teorico_totale,
                                  'seconds'
                                )
                                .hours()}
                              h{' '}
                              {moment
                                .duration(
                                  totals.tempo_teorico_totale,
                                  'seconds'
                                )
                                .minutes()}
                              m
                            </div>
                          </th>
                          <th>
                            <div className="badge badge-primary">
                              {moment
                                .duration(
                                  totals.tempo_lavorazione_totale,
                                  'seconds'
                                )
                                .days()}
                              g{' '}
                              {moment
                                .duration(
                                  totals.tempo_lavorazione_totale,
                                  'seconds'
                                )
                                .hours()}
                              h{' '}
                              {moment
                                .duration(
                                  totals.tempo_lavorazione_totale,
                                  'seconds'
                                )
                                .minutes()}
                              m{/* show numero gg numero hh numero mm */}
                            </div>
                          </th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th className="text-right">
                            <div className="badge badge-primary">
                              {totals.valore_economico_totale?.toFixed(2)}
                            </div>
                          </th>
                        </tr>
                        <tr>
                          <th>Data</th>
                          <th>Ordine</th>
                          <th>Tipo cliente</th>
                          <th>Tipo Evento</th>
                          <th>Lavorazione</th>
                          <th>Operatore</th>
                          <th>Tempo teo.</th>
                          <th>Tempo lav.</th>
                          <th>Numero</th>
                          <th>Materiale</th>
                          <th>Ord. Forn.</th>
                          <th>N° Doc</th>
                          <th>Valore economico</th>
                        </tr>
                      </thead>
                      <tbody>
                        {avanzamenti &&
                          avanzamenti?.map((av, i) => (
                            <tr
                              key={i}
                              className={
                                av.tipo_evento === 'arrivo_materiale'
                                  ? 'table-info'
                                  : ''
                              }
                            >
                              <td>{moment(av.data).format('DD/MM/YYYY')}</td>
                              <td>
                                {av.ordine_data?.numero_commessa} -{' '}
                                {av.ordine_data?.cliente?.nome}
                              </td>
                              <td>
                                {av.ordine_data.agente_data?.tipo_agente ? (
                                  av.ordine_data.agente_data?.tipo_agente
                                ) : (
                                  <span className="text-danger">
                                    Non definito
                                  </span>
                                )}
                              </td>
                              <td>
                                {AVANZAMENTI_TIPO_EVENTO[av.tipo_evento] ??
                                  av.tipo_evento}
                              </td>
                              <td>{av.lavorazione_data?.nome}</td>
                              <td>
                                {av.operatore_data?.user?.first_name}{' '}
                                {av.operatore_data?.user?.last_name}
                              </td>
                              <td>
                                {AVANZAMENTI_TIPO_EVENTO[av.tipo_evento] ===
                                  'Attività operatore' &&
                                  parseInt(
                                    moment
                                      .duration(av.tempo_teorico)
                                      .as('minutes')
                                  ) + ' min'}
                              </td>
                              <td>
                                {AVANZAMENTI_TIPO_EVENTO[av.tipo_evento] ===
                                  'Attività operatore' &&
                                  parseInt(
                                    moment
                                      .duration(av.tempo_lavorazione)
                                      .as('minutes')
                                  ) + ' min'}
                              </td>
                              <td>
                                {AVANZAMENTI_TIPO_EVENTO[av.tipo_evento] ===
                                  'Attività operatore' && (
                                  <>
                                    {av.numero_pezzi} / {av.numero_pezzi_totali}{' '}
                                    {av.lavorazione_data?.attivita_numero ===
                                    'numero'
                                      ? // ? check if is 1 or bigger than 1
                                        ' Telai'
                                      : av.lavorazione_data?.attivita_numero ===
                                        'n_vetri'
                                      ? ' Vetri'
                                      : ' Ante'}
                                  </>
                                )}
                              </td>
                              <td className="text-capitalize">
                                {av.materiale_data?.nome}
                              </td>
                              <td>
                                {av.ordine_fornitore_data?.fornitore_data?.nome}
                              </td>
                              <td>{av.numero_documento}</td>
                              <td className="text-right">
                                {av.valore_economico?.toFixed(2)}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <Paginator
                      numPages={pagination.numPages}
                      currentPage={page}
                      goToPage={goToPage}
                    />
                  </>
                )}
                {typeView === 'avanzamento-per-commessa' && (
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th>
                          <div className="badge badge-primary">
                            {avanzamentiCommesse &&
                              sumBy(
                                avanzamentiCommesse,
                                'ordine_data.netto'
                              ).toFixed(2)}
                          </div>
                        </th>
                        <th>
                          <div className="badge badge-primary">
                            {avanzamentiCommesse &&
                              sumBy(
                                avanzamentiCommesse,
                                'valore_economico_materiale'
                              ).toFixed(2)}
                          </div>
                        </th>
                        {intestazioneThMateriali.map((m, i) => (
                          <th key={i}>
                            <div className="badge badge-primary">
                              {avanzamentiCommesse &&
                                sumBy(
                                  avanzamentiCommesse,
                                  (av) =>
                                    av.valori_materiali.find(
                                      (valore) => valore.materiale === m
                                    )?.valore_economico_materiale
                                ).toFixed(2)}
                            </div>
                          </th>
                        ))}
                        <th>
                          <div className="badge badge-primary">
                            {avanzamentiCommesse &&
                              sumBy(
                                avanzamentiCommesse,
                                (av) =>
                                  av.totale_valore_economico -
                                  av.valore_economico_materiale
                              ).toFixed(2)}
                          </div>
                        </th>
                        <th>
                          <div className="badge badge-primary">
                            {avanzamentiCommesse &&
                              sumBy(
                                avanzamentiCommesse,
                                'totale_valore_economico'
                              ).toFixed(2)}
                          </div>
                        </th>
                        <th></th>
                      </tr>
                      <tr>
                        <th>Ordine</th>
                        <th>Tipo cliente</th>
                        <th>Netto ordine</th>
                        <th>Valore econ. materiali</th>
                        {intestazioneThMateriali.map((m, i) => (
                          <th key={i} className="text-capitalize">
                            Valore {m}
                          </th>
                        ))}
                        <th>Valore econ. manodopera</th>
                        <th>Totale valore economico</th>
                        <th>Percentuale avanzamento</th>
                      </tr>
                    </thead>
                    <tbody>
                      {avanzamentiCommesse &&
                        avanzamentiCommesse?.map((av, i) => (
                          <tr key={i}>
                            <td>
                              {av.ordine_data?.numero_commessa} -{' '}
                              {av.ordine_data?.cliente?.nome}
                              {av?.has_warnings_fonitori ? (
                                <Link
                                  to={`/ordini/${av.ordine_data?.id}/avanzamento`}
                                >
                                  <i
                                    className="fas fa-exclamation-triangle text-warning ml-2"
                                    title="Attenzione ordini fornitori non completi"
                                  ></i>
                                </Link>
                              ) : null}
                            </td>
                            <td>
                              {av.ordine_data.agente_data?.tipo_agente ? (
                                av.ordine_data.agente_data?.tipo_agente
                              ) : (
                                <span className="text-danger">
                                  Non definito
                                </span>
                              )}
                            </td>
                            <td>{av.ordine_data?.netto.toFixed(2)}</td>
                            <td>{av.valore_economico_materiale?.toFixed(2)}</td>
                            {intestazioneThMateriali.map((m, i) => (
                              <td key={i}>
                                {av.valori_materiali
                                  .find((valore) => valore.materiale === m)
                                  ?.valore_economico_materiale?.toFixed(2)}
                              </td>
                            ))}

                            <td>
                              {(
                                av.totale_valore_economico -
                                av.valore_economico_materiale
                              )?.toFixed(2)}
                            </td>
                            <td>{av.totale_valore_economico?.toFixed(2)}</td>
                            <td>{av.percentuale_avanzamento?.toFixed(2)}%</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
                {typeView === 'per-giorno' && (
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          <div className="badge badge-primary">
                            {avanzamentoPerGiorno
                              ? sumBy(
                                  avanzamentoPerGiorno
                                    .map((av) => av.valore_economico_materiale)
                                    .filter((v) => v),
                                  (v) => v
                                ).toFixed(2)
                              : 0}
                          </div>
                        </th>
                        <th>
                          <div className="badge badge-primary">
                            {avanzamentoPerGiorno
                              ? sumBy(
                                  avanzamentoPerGiorno
                                    .map((av) => av.totale_valore_economico)
                                    .filter((v) => v),
                                  (v) => v
                                ).toFixed(2)
                              : 0}
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th>Data</th>
                        <th>Valore econ. materiali</th>
                        <th>Totale valore economico</th>
                      </tr>
                    </thead>
                    <tbody>
                      {avanzamentoPerGiorno &&
                        avanzamentoPerGiorno?.map((av, i) => (
                          <tr key={i}>
                            <td>{moment(av.data).format('DD/MM/YYYY')}</td>

                            <td>{av.valore_economico_materiale?.toFixed(2)}</td>

                            <td>{av.totale_valore_economico?.toFixed(2)}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
