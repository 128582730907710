import React, { useMemo } from 'react'
import { useRunRj } from 'react-rocketjump'
import {
  Button,
  Card,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import CardBody from 'reactstrap/lib/CardBody'
import { AnalisiPostazioneDetail } from '../localstate/analisiPostazioni'
import { Field, FieldArray, Formik } from 'formik'
import { InputField } from '../../../components/Form/Fields'
import * as Yup from 'yup'
import { LavorazioniState } from '../localstate/lavorazioni'
import classNames from 'classnames'

const BIG_PAGE_PARAMS = { page_size: 1000 }

function calcTempoTeoricoRimanente(tempo, tempo_usato) {
  return Number(tempo) - Number(tempo_usato)
}

function calcTempoTeoricoRimanentePostazione(tempo, rimanenze, postazione) {
  let tempoRimanentePostazione = tempo
  if (rimanenze) {
    Object.keys(rimanenze).forEach((ordine) => {
      if (rimanenze[ordine][postazione]) {
        tempoRimanentePostazione = calcTempoTeoricoRimanente(
          tempoRimanentePostazione,
          rimanenze[ordine][postazione].tempo_teorico_usato
        )
      }
    })
  }
  return tempoRimanentePostazione
}

export default function AnalisiTempiDettaglio({ history, match }) {
  const { id } = match.params
  const [{ data: analisi }, { updateAnalisi }] = useRunRj(
    AnalisiPostazioneDetail,
    [id]
  )

  const [{ lavorazioni }] = useRunRj(LavorazioniState, [BIG_PAGE_PARAMS])
  const thColumnsPostazioni = useMemo(() => {
    if (!analisi) {
      return []
    }
    return Object.keys(analisi.configurazione).map((config) => ({
      Header: config,
      accessor: config,
    }))
  }, [analisi])

  const [modalConfig, setModalConfig] = React.useState(false)
  const toggleModalConfig = () => setModalConfig(!modalConfig)
  const [modalConfigValue, setModalConfigValue] = React.useState('')

  const [modalConfigAdd, setModalConfigAdd] = React.useState(false)
  const toggleModalConfigAdd = () => setModalConfigAdd(!modalConfigAdd)

  return (
    <div className="container-fluid p-3">
      <h3>
        <div>{analisi?.nome}</div>
        <div className="mt-1">
          {analisi?.ordini_data?.map((ordine) => (
            <span
              className="badge mr-2 mb-2"
              style={{
                fontSize: 14,
                backgroundColor: 'darkblue',
                color: 'white',
              }}
              key={ordine.id}
            >
              {ordine.numero_commessa} - {ordine.cliente_data.nome}
            </span>
          ))}
        </div>
      </h3>
      <Card className="mt-3">
        <CardHeader className="d-flex justify-content-between align-items-center">
          <div>Configurazione</div>
          <Button
            outline
            color="primary"
            size="sm"
            onClick={() => {
              setModalConfigAdd(true)
            }}
          >
            Aggiungi postazione
          </Button>
        </CardHeader>
        <CardBody>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Postazione</th>
                <th>Valore</th>
                <th>Azioni</th>
              </tr>
            </thead>
            {analisi &&
              Object.keys(analisi?.configurazione)?.map((config) => (
                <tr key={config}>
                  <td>
                    <strong>{config}</strong>
                  </td>
                  <td>
                    {analisi.configurazione[config]
                      .join(', ')
                      .split(',')
                      .map((item, i) => (
                        <div key={i}>{item}</div>
                      ))}
                  </td>
                  <td>
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => {
                        setModalConfigValue({
                          nome: config,
                          valori: analisi.configurazione[config],
                        })
                        setModalConfig(true)
                      }}
                    >
                      Modifica
                    </Button>
                    <Button
                      size="sm"
                      color="danger"
                      className="ml-2"
                      onClick={() => {
                        const newConfigurazione = { ...analisi.configurazione }
                        delete newConfigurazione[config]
                        updateAnalisi.asPromise({
                          ...analisi,
                          configurazione: newConfigurazione,
                        })
                      }}
                    >
                      Elimina
                    </Button>
                  </td>
                </tr>
              ))}
          </table>
        </CardBody>
      </Card>
      <Card className="mt-3">
        <CardHeader>Tempi</CardHeader>
        <CardBody>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th></th>
                {thColumnsPostazioni.map((th) => {
                  const totalValue = analisi?.totali?.[th.accessor]?.toFixed(2)
                  return (
                    <th key={th.accessor}>
                      <div
                        className={classNames('text-nowrap', 'mb-1', 'badge', {
                          'badge-primary':
                            calcTempoTeoricoRimanentePostazione(
                              totalValue,
                              analisi.rimanenze,
                              th.Header
                            ) > 0,
                          'badge-success':
                            calcTempoTeoricoRimanentePostazione(
                              totalValue,
                              analisi.rimanenze,
                              th.Header
                            ) <= 0,
                        })}
                      >
                        {
                          // calcola tempo rimanente per postazione totale
                          calcTempoTeoricoRimanentePostazione(
                            totalValue,
                            analisi.rimanenze,
                            th.Header
                          ) < 0 ? 0 : calcTempoTeoricoRimanentePostazione(
                            totalValue,
                            analisi.rimanenze,
                            th.Header
                          )
                        }
                        {' / '}
                        {totalValue}
                      </div>
                    </th>
                  )
                })}
              </tr>
              <tr>
                <th>Ordine</th>
                {thColumnsPostazioni.map((th) => (
                  <th key={th.accessor}>{th.Header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {analisi?.righe_analisi?.map((riga) => (
                <tr key={riga.id}>
                  <td>
                    {riga.ordine_data.numero_commessa} -{' '}
                    {riga.ordine_data.cliente_data.nome}
                  </td>
                  {thColumnsPostazioni.map((th) => {
                    const valore = riga.valori[th.accessor] || 0
                    const tempoTeoricoUsato =
                      analisi?.rimanenze?.[riga.ordine_data.id]?.[th.Header]
                        ?.tempo_teorico_usato || 0

                    const tempoRimanente = Math.max(
                      calcTempoTeoricoRimanente(
                        valore,
                        tempoTeoricoUsato
                      ).toFixed(2),
                      0
                    )

                    return (
                      <td key={th.accessor} style={{ fontSize: 14 }}>
                        <div className="text-nowrap">
                          {tempoRimanente} / {valore.toFixed(2)}
                        </div>
                      </td>
                    )
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </CardBody>
      </Card>
      <Modal
        isOpen={modalConfig}
        toggle={toggleModalConfig}
        className="modal-dialog-centered"
      >
        <ModalHeader>Modifica configurazione</ModalHeader>
        <Formik
          initialValues={{
            nome: modalConfigValue.nome,
            valori: modalConfigValue.valori,
          }}
          onSubmit={(values, { setSubmitting }) => {
            // prepare new configurazione_dict to update
            const newConfigurazione = {
              ...analisi.configurazione,
              [values.nome]:
                typeof values.valori === 'string'
                  ? values.valori.split(',')
                  : values.valori,
            }
            updateAnalisi
              .onSuccess(() => {
                setSubmitting(false)
                toggleModalConfig()
              })
              .asPromise({
                ...analisi,
                configurazione: newConfigurazione,
              })
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <>
              <form onSubmit={handleSubmit}>
                <ModalBody>
                  <Field
                    component={InputField}
                    label="Nome *"
                    name="nome"
                    type="text"
                    className="mb-2"
                  />
                  <div className="mb-2">
                    <label>Valori *</label>
                    <FieldArray
                      name="valori"
                      render={(arrayHelpers) => (
                        <>
                          {values.valori.map((valore, index) => (
                            <div
                              key={index}
                              className="d-flex align-items-center mb-1"
                            >
                              <Field
                                component={InputField}
                                type="select"
                                name={`valori.${index}`}
                              >
                                <option value=""></option>
                                {lavorazioni?.map((lavorazione) => (
                                  <option
                                    key={lavorazione.id}
                                    value={lavorazione.nome.toLowerCase()}
                                  >
                                    {lavorazione.nome}
                                  </option>
                                ))}
                              </Field>
                              <Button
                                color="danger"
                                size="sm"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                X
                              </Button>
                            </div>
                          ))}
                          <Button
                            color="primary"
                            className="mt-2"
                            size="sm"
                            onClick={() => arrayHelpers.push('')}
                          >
                            Aggiungi
                          </Button>
                        </>
                      )}
                    />
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={toggleModalConfig}>
                    Chiudi
                  </Button>
                  <Button color="success" type="submit">
                    Salva
                  </Button>
                </ModalFooter>
              </form>
            </>
          )}
        </Formik>
      </Modal>
      <Modal
        isOpen={modalConfigAdd}
        toggle={toggleModalConfigAdd}
        className="modal-dialog-centered"
      >
        <ModalHeader>Aggiungi configurazione</ModalHeader>
        <Formik
          initialValues={{
            nome: '',
            valori: [''],
          }}
          validationSchema={Yup.object().shape({
            nome: Yup.string().required('Nome obbligatorio'),
            valori: Yup.string().required('Valori obbligatori'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            // prepare new configurazione_dict to update
            const newConfigurazione = {
              ...analisi.configurazione,
              [values.nome]:
                typeof values.valori === 'string'
                  ? values.valori.split(',')
                  : values.valori,
            }
            updateAnalisi
              .onSuccess(() => {
                setSubmitting(false)
                toggleModalConfigAdd()
              })
              .asPromise({
                ...analisi,
                configurazione: newConfigurazione,
              })
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <>
              <form onSubmit={handleSubmit}>
                <ModalBody>
                  <Field
                    component={InputField}
                    label="Nome *"
                    name="nome"
                    type="text"
                    className="mb-2"
                  />
                  <div className="mb-2">
                    <label>Valori *</label>
                    <FieldArray
                      name="valori"
                      render={(arrayHelpers) => (
                        <>
                          {values.valori?.map((valore, index) => (
                            <div
                              key={index}
                              className="d-flex align-items-center mb-1"
                            >
                              <Field
                                component={InputField}
                                type="select"
                                name={`valori.${index}`}
                                className="form-select"
                              >
                                <option value=""></option>
                                {lavorazioni?.map((lavorazione) => (
                                  <option
                                    key={lavorazione.id}
                                    value={lavorazione.nome.toLowerCase()}
                                  >
                                    {lavorazione.nome}
                                  </option>
                                ))}
                              </Field>
                              <Button
                                color="danger"
                                size="sm"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                X
                              </Button>
                            </div>
                          ))}
                          <Button
                            color="primary"
                            className="mt-2"
                            size="sm"
                            onClick={() => arrayHelpers.push('')}
                          >
                            Aggiungi
                          </Button>
                        </>
                      )}
                    />
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={toggleModalConfigAdd}>
                    Chiudi
                  </Button>
                  <Button color="success" type="submit">
                    Salva
                  </Button>
                </ModalFooter>
              </form>
            </>
          )}
        </Formik>
      </Modal>
    </div>
  )
}
