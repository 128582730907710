import React, { useContext, useMemo, useEffect, useRef } from 'react'
import { Formik, Form, Field, FieldArray } from 'formik'
import { ModalBody, ModalFooter, Button } from 'reactstrap'
import capitalize from 'lodash/capitalize'
import * as Yup from 'yup'
import find from 'lodash/find'
import uniqueId from 'lodash/uniqueId'
import {
  transformErrorForForm,
  setNullOnEmptyValues,
  fixInitialValuesNull,
} from '../Form/Fields/utils'
import { ContextFornitori } from '../../apps/PlannerApp/contexts'
import { ConfigPlannerContext } from '../../context'
import { InputField, FileField, DateField, CheckboxField } from '../Form/Fields'
import { PERMISSIONS } from '../../django'
import OrdersSelectAutoComplete from '../OrdersSelectAutoComplete'

const INITIAL_FORM_VAULES = {
  fornitore: '',
  tipologia: '',
  stato: 'DA_FARE',
  data_consegna: '',
  note: '',
  testo_email: '',
  testo_pdf: '',
  allegati_data: [],
  domanda1: '',
  domanda2: '',
  domanda3: '',
}

const OrdineFornitoreSchema = Yup.object().shape({
  note: Yup.string(),
  testo_pdf: Yup.string(),
  testo_email: Yup.string(),
  fornitore: Yup.string().required(),
  tipologia: Yup.string().required(),
  data_consegna: Yup.date(),
  data_arrivo: Yup.date(),
  data_ordine: Yup.date(),
})

function Allegati({ remove, push, form }) {
  return (
    <div>
      <h5>Allegati:</h5>
      {PERMISSIONS.adminOrdiniFornitore && (
        <Button
          onClick={() =>
            push({ file: null, id: uniqueId('local~'), notUploaded: true })
          }
          color="success"
          outline
          size="sm"
          type="button"
        >
          <i className="fas fa-plus"></i>
        </Button>
      )}
      {form.values.allegati_data.map((allegato, i) => (
        <div className="d-flex mt-1 align-items-center" key={allegato.id}>
          {PERMISSIONS.adminOrdiniFornitore && (
            <i
              onClick={() => remove(i)}
              className="fas fa-trash text-danger pointer mr-2"
            ></i>
          )}
          {allegato.notUploaded !== true ? (
            <div>
              <a href={allegato.allegato}>
                {allegato.original_name || 'Allegato'}
              </a>
            </div>
          ) : (
            <Field
              type="file"
              name={`allegati_data[${i}].file`}
              component={FileField}
              className="form-control"
            />
          )}
        </div>
      ))}
    </div>
  )
}

function FormFields({
  fornitori,
  stati,
  tipologia,
  tipologie,
  setFieldValue,
  values,
  orderFornitore,
  showOrder,
}) {
  const fornitoriForFase = useMemo(
    () => fornitori.filter((f) => f.tipologia === tipologia),
    [fornitori, tipologia]
  )

  const prevTipolgia = useRef(null)
  useEffect(() => {
    if (prevTipolgia.current === null) {
      prevTipolgia.current = tipologia
    } else if (prevTipolgia.current !== tipologia) {
      prevTipolgia.current = tipologia
      setFieldValue('fornitore', '', false)
    }
  }, [tipologia, setFieldValue])

  return (
    <div className="row">
      {showOrder && (
        <>
          <div className="col-12">
            <label className="mb-0">Ordine</label>
            <OrdersSelectAutoComplete
              value={
                orderFornitore && orderFornitore.ordine_data
                  ? values.ordine_data.id
                  : values.ordine
              }
              hasValue
              debounceTimeout={150}
              isDisabled={orderFornitore}
              loadOptionsOnMenuOpen
              onChange={(ordine) => {
                if (ordine) {
                  setFieldValue('ordine', ordine.id)
                } else {
                  setFieldValue('ordine', '')
                }
              }}
              noOptionsMessage={() => 'Nessun ordine trovato'}
              loadingMessage={() => 'Caricamento ordini...'}
              placeholder={
                orderFornitore && orderFornitore.ordine_data
                  ? 'Seleziona un ordine'
                  : orderFornitore
                  ? 'Nessun ordine associato'
                  : 'Seleziona un ordine'
              }
              isClearable={true}
              name={'ordine'}
              additional={{
                page: 1,
              }}
            />
          </div>
        </>
      )}
      <div className="col-6">
        {tipologie && (
          <Field
            disabled={!PERMISSIONS.adminOrdiniFornitore}
            label="Tipologia"
            type="select"
            name="tipologia"
            component={InputField}
            className="form-control"
          >
            <option value="">{'--- Scegli Tipologia ---'}</option>
            {tipologie.map((tipologia) => (
              <option key={tipologia} value={tipologia}>
                {capitalize(tipologia)}
              </option>
            ))}
          </Field>
        )}
      </div>
      <div className="col-6">
        <Field
          disabled={!PERMISSIONS.adminOrdiniFornitore}
          label="Fornitore"
          type="select"
          name="fornitore"
          component={InputField}
          className="form-control"
        >
          <option value="">{'--- Scegli Fornitore ---'}</option>
          {fornitoriForFase.map((fornitore) => (
            <option key={fornitore.id} value={fornitore.id}>
              {fornitore.nome}
            </option>
          ))}
        </Field>
      </div>
      <div className="col-6">
        <Field
          label="Stato"
          type="select"
          name="stato"
          component={InputField}
          className="form-control"
        >
          <option value=""></option>
          {stati.map((stato) => (
            <option key={stato.nome} value={stato.nome}>
              {stato.nome}
            </option>
          ))}
        </Field>
      </div>
      <div className="col-6">
        <Field
          disabled={!PERMISSIONS.adminOrdiniFornitore}
          label="Valore percentuale"
          type="text"
          name="valore_percentuale"
          component={InputField}
          className="form-control"
        />
      </div>
      <div className="col-6">
        <Field
          disabled={!PERMISSIONS.adminOrdiniFornitore}
          label="Numero documento"
          type="text"
          name="numero_documento"
          component={InputField}
          className="form-control"
        />
      </div>
      <div className="col-6">
        <Field
          disabled={!PERMISSIONS.adminOrdiniFornitore}
          label="Data ordine"
          type="text"
          name="data_ordine"
          component={DateField}
          className="form-control"
        />
      </div>
      <div className="col-6">
        <Field
          label="Data consegna prevista"
          type="text"
          name="data_consegna"
          component={DateField}
          className="form-control"
        />
      </div>
      <div className="col-6">
        <Field
          label="Data consegna effettiva"
          type="text"
          name="data_arrivo"
          component={DateField}
          className="form-control"
        />
      </div>
      {values.stato === 'ARRIVATO' && values.data_arrivo && values.tipologia === 'verniciatura' && (
        <>
          <div className="col-12 mt-2">
            <Field
              label="Hai verificato la corrispondenza dei colori dei serramenti rispetto ad un eventuale  campione fatto per il cliente , o alla mazzetta colori dei mordenzati qr legno , o dalla tabella Ral?"
              type="select"
              name="domanda1"
              component={InputField}
              className="form-control"
            >
              <option value=""></option>
              <option value={true}>SI</option>
              <option value={false}>NO</option>
            </Field>
          </div>
          <div className="col-12 mt-2">
            <Field
              label="Hai verificato in caso di bicolore il lato corretto di applicazione rispetto al foglio di verniciatura controfirmato dal verniciatore?"
              type="select"
              name="domanda2"
              component={InputField}
              className="form-control"
            >
              <option value=""></option>
              <option value={true}>SI</option>
              <option value={false}>NO</option>
            </Field>
          </div>
          <div className="col-12 mt-2">
            <Field
              label="Hai verificato la qualità della verniciatura?"
              type="select"
              name="domanda3"
              component={InputField}
              className="form-control"
            >
              <option value=""></option>
              <option value={true}>SI</option>
              <option value={false}>NO</option>
            </Field>
          </div>
        </>
      )}
      <div className="col-12 mt-2">
        <Field
          disabled={!PERMISSIONS.adminOrdiniFornitore}
          label="Note"
          type="textarea"
          rows={3}
          name="note"
          component={InputField}
          className="form-control"
        />
      </div>
      <div className="col-12">
        {((values.ordine && showOrder) || !showOrder) && (
          <Field
            disabled={!PERMISSIONS.adminOrdiniFornitore}
            label="Testo per PDF"
            type="textarea"
            rows={3}
            name="testo_pdf"
            component={InputField}
            className="form-control"
          />
        )}
      </div>
      <div className="col-12">
        {((values.ordine && showOrder) || !showOrder) && (
          <Field
            disabled={!PERMISSIONS.adminOrdiniFornitore}
            label="Testo per email"
            type="textarea"
            rows={3}
            name="testo_email"
            component={InputField}
            className="form-control"
          />
        )}
      </div>
      <div className="col-12">
        {((values.ordine && showOrder) || !showOrder) && (
          <Field
            type="file"
            label={'Immagine pdf'}
            name={`immagine_pdf`}
            component={FileField}
            className="form-control"
          />
        )}
        {((values.ordine && showOrder) || !showOrder) && (
          <>
            {orderFornitore &&
              orderFornitore.id &&
              orderFornitore.immagine_pdf && (
                <Field
                  component={CheckboxField}
                  label="Vuoi eliminare l'immagine pdf?"
                  name="remove_immagine_pdf"
                  className="mb-2"
                />
              )}

            {orderFornitore &&
              orderFornitore.id &&
              orderFornitore.immagine_pdf && (
                <div className="mt-1 mb-1">
                  <img
                    src={orderFornitore.immagine_pdf}
                    width="100"
                    alt="Immagine pdf"
                  />
                </div>
              )}
          </>
        )}
        <FieldArray name="allegati_data" component={Allegati} />
      </div>
    </div>
  )
}

const EMPTY_LIST = []

export default function OrderFornitoreForm({
  save,
  tipologia,
  tipologie,
  orderFornitore,
  toggle,
  inModal = false,
  showOrder = false,
}) {
  const fornitori = useContext(ContextFornitori)
  const { valoriStato } = useContext(ConfigPlannerContext)
  const stati = valoriStato.ordine_fornitore || EMPTY_LIST

  const initialValues = useMemo(() => {
    if (orderFornitore) {
      return fixInitialValuesNull({
        ...orderFornitore,
        remove_immagine_pdf: false,
      })
    }
    let values = INITIAL_FORM_VAULES

    if (tipologia) {
      const fornitoriForFase = fornitori.filter(
        (f) => f.tipologia === tipologia
      )
      const defaultFornitore = find(fornitoriForFase, { is_default: true })
      if (defaultFornitore) {
        values = { ...values, tipologia, fornitore: defaultFornitore.id }
      } else {
        values = { ...values, tipologia }
      }
    }
    return values
  }, [orderFornitore, tipologia, fornitori])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={OrdineFornitoreSchema}
      onSubmit={(values, actions) => {
        return save(
          setNullOnEmptyValues({
            ...values,
            domanda1: values.domanda1 === 'true' ? true : false,
            domanda2: values.domanda2 === 'true' ? true : false,
            domanda3: values.domanda3 === 'true' ? true : false,
          })
        ).then(
          () => {
            actions.setSubmitting(false)
          },
          (error) => {
            actions.setSubmitting(false)
            actions.setErrors(transformErrorForForm(error))
          }
        )
      }}
    >
      {({ isSubmitting, values, setFieldValue }) =>
        inModal ? (
          <Form className="p-0 m-0">
            <ModalBody>
              <FormFields
                setFieldValue={setFieldValue}
                fornitori={fornitori}
                showOrder={showOrder}
                tipologie={tipologie}
                tipologia={values.tipologia}
                values={values}
                orderFornitore={orderFornitore}
                fornitore={values.fornitore}
                stati={stati}
              />
            </ModalBody>
            <ModalFooter className="justify-content-between">
              <Button disabled={isSubmitting} type="button" onClick={toggle}>
                Annulla
              </Button>
              <Button disabled={isSubmitting} color="success" type="submit">
                Salva
              </Button>
            </ModalFooter>
          </Form>
        ) : (
          <Form>
            <FormFields
              setFieldValue={setFieldValue}
              fornitori={fornitori}
              tipologie={tipologie}
              tipologia={values.tipologia}
              values={values}
              fornitore={values.fornitore}
              showOrder={showOrder}
              orderFornitore={orderFornitore}
              stati={stati}
            />
            <div>
              <Button disabled={isSubmitting} color="success" type="submit">
                Salva
              </Button>
            </div>
          </Form>
        )
      }
    </Formik>
  )
}
