import React from "react"

const accessorMagikIds = new Set([
  "AddEntityAccessor",
  "EditEntityAccessor",
  "ViewEntityAccessor",
  "DeleteEntityAccessor",
])

export const AccessorContext = React.createContext({ disabled: false })

export function useAccessorContext() {
  return React.useContext(AccessorContext)
}

export function isNodeAnAccessor(node){
  return React.isValidElement(node) && typeof node.type !== "string" && accessorMagikIds.has((node.type).magikID)
}
