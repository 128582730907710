import { rj } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import rjDebounce from 'react-rocketjump/plugins/debounce'
import rjPlainList from 'react-rocketjump/plugins/plainList'
import qs from 'query-string'
import { ajax } from 'rxjs/ajax'
import { CSRF, SETTINGS } from '../../../django'
import { rjListWithTotals } from './common'
import { map } from 'rxjs/operators'

export const AnalisiPostazioniState = rj(
  rjDebounce(),
  rjList({
    pageSize: SETTINGS.PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter,
  }),
  rjListWithTotals(),
  {
    name: 'AnalisiPostazioniState',
    effect: (params = {}) =>
      ajax.getJSON(`/api/planner/analisi-postazione/?${qs.stringify(params)}`),
    mutations: {
      addAnalisi: {
        effect: (analisi) =>
          ajax({
            method: 'post',
            url: `/api/planner/analisi-postazione/`,
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': CSRF,
            },
            body: JSON.stringify(analisi),
          }).pipe(map((r) => r.response)),
        updater: 'insertItem',
      },
      updateAnalisi: {
        effect: (analisi) =>
          ajax({
            method: 'put',
            url: `/api/planner/analisi-postazione/${analisi.id}/`,
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': CSRF,
            },
            body: JSON.stringify(analisi),
          }).pipe(map((r) => r.response)),
        updater: 'updateItem',
      },
      deleteAnalisi: {
        effect: (analisi) =>
          ajax({
            method: 'delete',
            url: `/api/planner/analisi-postazione/${analisi.id}/`,
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': CSRF,
            },
          }).pipe(map(() => analisi)),
        updater: 'deleteItem',
      },
    },
    computed: {
      analisiPostazioni: 'getList',
      totals: 'getTotals',
      pagination: 'getPagination',
      loading: 'isLoading',
      error: 'getError',
    },
  }
)

export const AnalisiPostazioneDetail = rj({
  name: 'AnalisiPostazioneDetail',
  computed: {
    data: 'getData',
    loading: 'isLoading',
    error: 'getError',
  },
  effect: (id) => ajax.getJSON(`/api/planner/analisi-postazione/${id}/`),
  mutations: {
    updateAnalisi: {
      effect: (analisi) =>
        ajax({
          method: 'put',
          url: `/api/planner/analisi-postazione/${analisi.id}/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(analisi),
        }).pipe(map((r) => r.response)),
        updater: 'updateData',
    },
  },
})

export const ConfigurazionePostazioneState = rj(rjPlainList(), {
  name: 'ConfigurazionePostazioneState',
  computed: {
    list: 'getList',
    loading: 'isLoading',
    error: 'getError',
  },
  effect: (id, params = {}) =>
    ajax.getJSON(
      `/api/planner/configurazione-postazione/`
    ),
})
